import axios from 'axios';

const SUPABASE_URL = 'https://infnamnlgddsnzcttpfq.supabase.co';
const SUPABASE_BUCKET = 'business_logos';
const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImluZm5hbW5sZ2Rkc256Y3R0cGZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgwOTU3NTMsImV4cCI6MjA0MzY3MTc1M30.euUMOwXDZQv3Lu2UvPEZXj5pj200JOi4rEuaiGHwTg4';

export const uploadImageToSupabase = async (file) => {
  try {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random().toString(36).slice(2)}-${Date.now()}.${fileExt}`;
    const filePath = `${SUPABASE_BUCKET}/${fileName}`;

    const formData = new FormData();
    formData.append('file', file);

    const { data, error } = await axios.post(
      `${SUPABASE_URL}/storage/v1/object/${SUPABASE_BUCKET}/${fileName}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${SUPABASE_KEY}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (error) {
      throw new Error(error.message);
    }

    const publicUrl = `${SUPABASE_URL}/storage/v1/object/public/${filePath}`;
    return publicUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};
