// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   fetchDeals,
//   toggleDealStatus,
//   updateDeal,
//   addDeal,
// } from '../../redux/actions/dealActions';
// import { getVendorsAdmin, getCategoriesAdmin } from '../../api/api';
// import { fetchLocations, addLocation } from '../../redux/actions/storeLocationActions';
// import { uploadImageToSupabase } from '../../utils/uploadImages';


// const Deals = () => {
//   const dispatch = useDispatch();
//   const { deals, error } = useSelector((state) => state.deals || {});

//   const [showEditModal, setShowEditModal] = useState(false);
//   const [showAddModal, setShowAddModal] = useState(false);
//   const [showDeactivateModal, setShowDeactivateModal] = useState(false);
//   const { locations } = useSelector((state) => state.storeLocations || {});

//   const [vendors, setVendors] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [selectedDeal, setSelectedDeal] = useState(null);
//   const [notification, setNotification] = useState(null);
//   const [dealData, setDealData] = useState({});
//   const [addDealData, setAddDealData] = useState({
//     vendor_id: '',
//     category_id: '',
//     title: '',
//     description: '',
//     available_from: '',
//     available_to: '',
//     original_price: '',
//     discount: '',
//     net_price: '',
//     deal_coupon_total_count: '',
//     terms_and_conditions: '',
//     product_service: '',
//     images: [],
//     store_location_ids: [],
//   });
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [imageFiles, setImageFiles] = useState([]);
//   const [dealIdInput, setDealIdInput] = useState("");

//   useEffect(() => {
//     dispatch(fetchDeals());
//     fetchVendorsAndCategories();
//   }, [dispatch]);

//   const handleAddLocation = () => {
//     const locationData = {
//       vendor_id: addDealData.vendor_id,
//       location_name: prompt('Enter new store location name:'),
//     };
//     if (locationData.location_name) {
//       dispatch(addLocation(locationData));
//     }
//   };

//   const fetchVendorsAndCategories = async () => {
//     try {
//       const fetchedVendors = await getVendorsAdmin();
//       const fetchedCategories = await getCategoriesAdmin();
//       console.log('Fetched Categories:', fetchedCategories); // Debugging output
//       console.log('Fetched Vendors:', fetchedVendors); // Debugging output
//       setVendors(fetchedVendors);
//       setCategories(fetchedCategories);
//     } catch (error) {
//       console.error('Error fetching vendors or categories:', error);
//     }
//   };
  

//   const handleStatusToggle = (deal) => {
//     setSelectedDeal(deal);
//     setShowDeactivateModal(true);
//   };

//   const handleConfirmDeactivation = () => {
//     const isActive = selectedDeal.deal_status === 'Active';
//     dispatch(toggleDealStatus(selectedDeal.deal_id, isActive));
//     setNotification(`Deal ${isActive ? 'deactivated' : 'activated'} successfully`);
//     closeDeactivateModal();
//   };

//   const closeDeactivateModal = () => {
//     setShowDeactivateModal(false);
//     setDealIdInput("");
//     setSelectedDeal(null);
//   };

//   const handleEditClick = (deal) => {
//     setSelectedDeal(deal);
//     setDealData({
//       ...deal,
//       available_from: deal.available_from.split('T')[0],
//       available_to: deal.available_to.split('T')[0],
//     });
//     setShowEditModal(true);
//   };

//   const handleUpdateDeal = () => {
//     const updatedFields = Object.keys(dealData).reduce((acc, key) => {
//       if (dealData[key] !== selectedDeal[key]) {
//         acc[key] = dealData[key];
//       }
//       return acc;
//     }, {});

//     if (Object.keys(updatedFields).length === 0) {
//       setNotification("No changes were made.");
//       closeEditModal();
//       return;
//     }

//     dispatch(updateDeal(selectedDeal.deal_id, updatedFields));
//     closeEditModal();
//   };
//   const nextImage = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex < dealData.deal_images.length - 1 ? prevIndex + 1 : 0
//     );
//   };

//   const prevImage = () => {
//     setCurrentImageIndex((prevIndex) =>
//       prevIndex > 0 ? prevIndex - 1 : dealData.deal_images.length - 1
//     );
//   };

//   const closeEditModal = () => {
//     setShowEditModal(false);
//     setSelectedDeal(null);
//     setDealData({});
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     if (showEditModal) {
//       setDealData((prevData) => {
//         let updatedData = { ...prevData, [name]: value };

//         // Update Net Price when Original Price or Discount changes
//         if (name === 'original_price' || name === 'discount') {
//           const originalPrice = parseFloat(updatedData.original_price) || 0;
//           const discount = parseFloat(updatedData.discount) || 0;

//           // Ensure Net Price is always calculated correctly
//           updatedData.net_price = (originalPrice - discount).toFixed(2);
//         }

//         return updatedData;
//       });
//     } else {
//       setAddDealData((prevData) => {
//         let updatedData = { ...prevData, [name]: value };

//         // Update Net Price when Original Price or Discount changes
//         if (name === 'original_price' || name === 'discount') {
//           const originalPrice = parseFloat(updatedData.original_price) || 0;
//           const discount = parseFloat(updatedData.discount) || 0;

//           // Ensure Net Price is always calculated correctly
//           updatedData.net_price = (originalPrice - discount).toFixed(2);
//         }

//         return updatedData;
//       });
//     }
//   };

//   const handleImageChange = (e) => {
//     const files = Array.from(e.target.files);
//     setImageFiles((prevFiles) => [...prevFiles, ...files]);
//     setAddDealData((prevData) => ({
//       ...prevData,
//       images: [...prevData.images, ...files.map((file) => URL.createObjectURL(file))],
//     }));
//   };

//   const handleVendorChange = (vendorId) => {
//     setAddDealData((prevData) => ({
//       ...prevData,
//       vendor_id: vendorId,
//       store_location_ids: []
//     }));
    
//     if (vendorId) {
//       dispatch(fetchLocations(vendorId));
//       console.log(locations)
//     }
//   };

//   const handleRemoveImage = (index) => {
//     setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
//     setAddDealData((prevData) => ({
//       ...prevData,
//       images: prevData.images.filter((_, i) => i !== index),
//     }));
//   };

//   const uploadImagesAndAddDeal = async () => {
//     try {
//       // Upload images to Supabase and get their public URLs
//       const uploadedImageUrls = await Promise.all(
//         imageFiles.map((file) => uploadImageToSupabase(file))
//       );
  
//       // Proceed to add the deal with the uploaded image URLs
//       handleAddDeal(uploadedImageUrls);
//     } catch (error) {
//       console.error('Error uploading images:', error);
//       setNotification('Error uploading images. Please try again.');
//     }
//   };

//   const handleAddDeal = (uploadedImageUrls) => {
//     if (!addDealData.vendor_id || !addDealData.store_location_ids) {
//       setNotification('Please select a vendor and store location before adding a deal.');
//       return;
//     }
  
//     const dealPayload = {
//       ...addDealData,
//       store_location_ids: Array.isArray(addDealData.store_location_ids)
//         ? addDealData.store_location_ids
//         : [addDealData.store_location_ids], // Ensure store_location_ids is an array
//       images: uploadedImageUrls,
//     };
  
//     dispatch(addDeal(dealPayload));
//     setShowAddModal(false);
//     setAddDealData({
//       vendor_id: '',
//       category_id: '',
//       title: '',
//       description: '',
//       available_from: '',
//       available_to: '',
//       original_price: '',
//       discount: '',
//       net_price: '',
//       deal_coupon_total_count: '',
//       terms_and_conditions: '',
//       product_service: '',
//       images: [],
//       store_location_ids: [],
//     });
//     setImageFiles([]);
//   };
//   const closeAddModal = () => {
//     setShowAddModal(false);
//     setAddDealData({
//       vendor_id: '',
//       category_id: '',
//       title: '',
//       description: '',
//       available_from: '',
//       available_to: '',
//       original_price: '',
//       discount: '',
//       net_price: '',
//       deal_coupon_total_count: '',
//       terms_and_conditions: '',
//       product_service: '',
//       images: [],
//       store_location_ids: [],
//     });
//     setImageFiles([]);
//   };

//   const filteredDeals = deals?.filter(
//     (deal) =>
//       deal.deal_id.includes(searchTerm) ||
//       deal.vendor_id.includes(searchTerm) ||
//       deal.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="p-6">
//       <h1 className="text-2xl font-bold mb-4">Deals</h1>

//       {notification && (
//         <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
//           <span>{notification}</span>
//           <button className="ml-4" onClick={() => setNotification(null)}>
//             ✖
//           </button>
//         </div>
//       )}

//       {/* Search bar and Add Deal button */}
//       <div className="flex justify-between items-center mb-4">
//         <input
//           type="text"
//           placeholder="Search by Deal ID, Vendor ID, or Title"
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           className="border border-gray-300 rounded px-4 py-2 w-1/2"
//         />
//         <button
//           className="bg-blue-500 text-white px-4 py-2 rounded"
//           onClick={() => setShowAddModal(true)}
//         >
//           Add Deal
//         </button>
//       </div>

//       {/* Table */}
//       <div className="bg-white shadow-md rounded-lg overflow-hidden">
//         <table className="min-w-full text-left">
//           <thead>
//             <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
//               <th className="py-3 px-6 font-semibold text-left">Deal ID</th>
//               <th className="py-3 px-6 font-semibold text-left">Vendor ID</th>
//               <th className="py-3 px-6 font-semibold text-left">Title</th>
//               <th className="py-3 px-6 font-semibold text-left">Original Price</th>
//               <th className="py-3 px-6 font-semibold text-left">Net Price</th>
//               <th className="py-3 px-6 font-semibold text-left">Actions</th>
//             </tr>
//           </thead>
//           <tbody className="text-gray-700 text-sm font-light">
//             {filteredDeals &&
//               filteredDeals.map((deal) => (
//                 <tr
//                   key={deal.deal_id}
//                   className={`border-b border-gray-200 ${
//                     deal.deal_status === 'Active' ? 'bg-green-50' : 'bg-red-50'
//                   } hover:bg-gray-100`}
//                 >
//                   <td className="py-3 px-6 font-medium text-blue-600">{deal.deal_id.split('_')[1]}</td>
//                   <td className="py-3 px-6 font-bold">{deal.vendor_id.split('_')[1]}</td>
//                   <td className="py-3 px-6 font-bold">{deal.title}</td>
//                   <td className="py-3 px-6 font-bold">${deal.original_price.toFixed(2)}</td>
//                   <td className="py-3 px-6 font-bold">${deal.net_price.toFixed(2)}</td>
//                   <td className="py-3 px-6">
//                     <div className="flex items-center space-x-12">
//                         <button
//                         onClick={() => handleEditClick(deal)}
//                         className="text-blue-500 font-medium"
//                         >
//                         View/Edit
//                         </button>
//                         <button
//                         onClick={() => handleStatusToggle(deal)}
//                         className={`font-medium px-4 py-2 rounded ${
//                             deal.deal_status === 'Active'
//                             ? 'bg-red-500 text-white'
//                             : 'bg-blue-500 text-white'
//                         } hover:opacity-80 transition-opacity`}
//                         >
//                         {deal.deal_status === 'Active' ? 'Deactivate' : 'Activate'}
//                         </button>
//                     </div>
//                     </td>

//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Deactivate Modal */}
//       {showDeactivateModal && selectedDeal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
//             <button onClick={closeDeactivateModal} className="absolute top-2 right-2 text-gray-500">
//               ✖
//             </button>
//             <h2 className="text-xl font-bold mb-4">Deactivate Deal</h2>
//             <p>
//               Would you like to deactivate the deal with Deal ID:{" "}
//               <strong>{selectedDeal.deal_id.split("_")[1]}</strong>?
//             </p>
//             <input
//               type="text"
//               value={dealIdInput}
//               onChange={(e) => setDealIdInput(e.target.value)}
//               placeholder="Enter Deal ID to confirm"
//               className="border border-gray-300 rounded w-full p-2 mt-4 mb-4"
//             />
//             <div className="flex justify-center space-x-4">
//               <button
//                 onClick={closeDeactivateModal}
//                 className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
//               >
//                 Cancel
//               </button>
//               <button
//                 onClick={handleConfirmDeactivation}
//                 className="bg-red-500 text-white px-4 py-2 rounded"
//                 disabled={dealIdInput !== selectedDeal.deal_id.split("_")[1]}
//               >
//                 {selectedDeal.deal_status === 'Active' ? 'Deactivate' : 'Activate'}
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//                   {/* Edit Modal */}
//             {showEditModal && selectedDeal && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
//             <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">✖</button>
            
//             {/* Left Section - Deal Images */}
//             <div className="flex flex-col items-center pr-6 border-r border-gray-200">
//               <h3 className="text-lg font-bold mb-2">Deal Name: {dealData.title}</h3>
//               {dealData.deal_images && dealData.deal_images.length > 0 ? (
//                 <div className="relative">
//                   <img
//                     src={dealData.deal_images[currentImageIndex]}
//                     alt="Deal Image"
//                     className="h-64 w-full object-cover rounded mb-4"
//                   />
//                   <button onClick={prevImage} className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2">{"<"}</button>
//                   <button onClick={nextImage} className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2">{">"}</button>
//                   <div className="flex justify-center mt-2 space-x-1">
//                     {dealData.deal_images.map((_, index) => (
//                       <span
//                         key={index}
//                         className={`h-2 w-2 rounded-full ${index === currentImageIndex ? "bg-yellow-500" : "bg-gray-300"}`}
//                       />
//                     ))}
//                   </div>
//                 </div>
//               ) : (
//                 <p>No images available</p>
//               )}
//             </div>

//             {/* Right Section - Deal Details with Scrollable Content */}
//             <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
//               <div>
//                 <label><strong>Title:</strong></label>
//                 <input
//                   type="text"
//                   name="title"
//                   value={dealData.title}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Description:</strong></label>
//                 <input
//                   type="text"
//                   name="description"
//                   value={dealData.description}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Available From:</strong></label>
//                 <input
//                   type="date"
//                   name="available_from"
//                   value={dealData.available_from}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Available To:</strong></label>
//                 <input
//                   type="date"
//                   name="available_to"
//                   value={dealData.available_to}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Product Service:</strong></label>
//                 <input
//                   type="text"
//                   name="product_service"
//                   value={dealData.product_service}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Original Price:</strong></label>
//                 <input
//                   type="number"
//                   name="original_price"
//                   value={dealData.original_price}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Net Price:</strong></label>
//                 <input
//                   type="number"
//                   name="Net_price"
//                   value={dealData.net_price}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Discount:</strong></label>
//                 <input
//                   type="number"
//                   name="discount"
//                   value={dealData.discount}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Total Coupon Count:</strong></label>
//                 <input
//                   type="number"
//                   name="deal_coupon_total_count"
//                   value={dealData.deal_coupon_total_count}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Remaining Coupon Count:</strong></label>
//                 <input
//                   type="number"
//                   name="deal_coupon_remaining_count"
//                   value={dealData.deal_coupon_remaining_count}
//                   onChange={handleInputChange}
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Category Name:</strong></label>
//                 <input
//                   type="text"
//                   name="category_name"
//                   value={dealData.category_name}
//                   disabled
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Created At:</strong></label>
//                 <input
//                   type="text"
//                   name="created_at"
//                   value={dealData.created_at.split('T')[0]}
//                   disabled
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <div>
//                 <label><strong>Updated At:</strong></label>
//                 <input
//                   type="text"
//                   name="updated_at"
//                   value={dealData.updated_at.split('T')[0]}
//                   disabled
//                   className="border border-gray-300 rounded w-full p-2"
//                 />
//               </div>
//               <button
//                 onClick={handleUpdateDeal}
//                 className="bg-blue-500 text-white px-4 py-2 rounded"
//                 >
//                 Save Changes
//             </button>

//             </div>
//           </div>
//         </div>
//       )}
//     {/* Add Deal Modal */}
// {showAddModal && (
//   <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//     <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
//       <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
//         ✖
//       </button>

//       {/* Left Section - Add Images */}
//       <div className="flex flex-col items-center pr-6 border-r border-gray-200">
//         <h3 className="text-lg font-bold mb-2">Add Deal Images</h3>
//         {addDealData.images && addDealData.images.length > 0 ? (
//           <div className="relative">
//             <img
//               src={addDealData.images[currentImageIndex]}
//               alt="Deal Preview"
//               className="h-64 w-full object-cover rounded mb-4"
//             />
//             <button
//               onClick={() =>
//                 setCurrentImageIndex((prevIndex) =>
//                   prevIndex > 0 ? prevIndex - 1 : addDealData.images.length - 1
//                 )
//               }
//               className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2"
//             >
//               {'<'}
//             </button>
//             <button
//               onClick={() =>
//                 setCurrentImageIndex((prevIndex) =>
//                   prevIndex < addDealData.images.length - 1 ? prevIndex + 1 : 0
//                 )
//               }
//               className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
//             >
//               {'>'}
//             </button>
//             <div className="flex justify-center mt-2 space-x-1">
//               {addDealData.images.map((_, index) => (
//                 <span
//                   key={index}
//                   className={`h-2 w-2 rounded-full ${
//                     index === currentImageIndex ? 'bg-yellow-500' : 'bg-gray-300'
//                   }`}
//                 />
//               ))}
//             </div>
//             <button
//               onClick={() => handleRemoveImage(currentImageIndex)}
//               className="bg-red-500 text-white px-2 py-1 rounded mt-2"
//             >
//               Remove Image
//             </button>
//           </div>
//         ) : (
//           <p>No images added</p>
//         )}
//         <input
//           type="file"
//           multiple
//           accept="image/*"
//           onChange={handleImageChange}
//           className="mt-4"
//         />
//       </div>

//       {/* Right Section - Deal Details */}
//       <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
//         <div>
//           <label>
//             <strong>Vendor ID:</strong>
//           </label>
//           <select
//             name="vendor_id"
//             value={addDealData.vendor_id}
//             onChange={(e) => handleVendorChange(e.target.value)}
//             className="border border-gray-300 rounded w-full p-2"
//           >
//             <option value="">Select Vendor</option>
//             {vendors
//               .filter((vendor) => vendor.approved_status === true)
//               .map((vendor) => (
//                 <option key={vendor.vendor_id} value={vendor.vendor_id}>
//                   {vendor.business_name}
//                 </option>
//               ))}
//           </select>
//         </div>
//         <div>
//           <label>
//             <strong>Category:</strong>
//           </label>
//           <select
//             name="category_id"
//             value={addDealData.category_id}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           >
//             <option value="">Select Category</option>
//             {categories.categories &&
//               categories.categories.length > 0 &&
//               categories.categories.map((category) => (
//                 <option key={category.category_id} value={category.category_id}>
//                   {category.name}
//                 </option>
//               ))}
//           </select>
//         </div>
//         <div>
//           <label>
//             <strong>Store Location:</strong>
//           </label>
//           {locations && locations.locations && locations.locations.length > 0 ? (
//             <select
//               name="store_location_ids"
//               value={addDealData.store_location_ids}
//               onChange={handleInputChange}
//               className="border border-gray-300 rounded w-full p-2"
//             >
//               <option value="">Select Store Location</option>
//               {locations.locations.map((location) => (
//                 <option key={location.location_id} value={location.location_id}>
//                   {location.address}, {location.zipcode}
//                 </option>
//               ))}
//             </select>
//           ) : (
//             <div className="flex items-center space-x-4">
//               <p>No active store locations available.</p>
//               <button
//                 onClick={handleAddLocation}
//                 className="bg-blue-500 text-white px-4 py-2 rounded"
//               >
//                 Add Store Location
//               </button>
//             </div>
//           )}
//         </div>
//         <div>
//           <label>
//             <strong>Title:</strong>
//           </label>
//           <input
//             type="text"
//             name="title"
//             value={addDealData.title}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Description:</strong>
//           </label>
//           <textarea
//             name="description"
//             value={addDealData.description}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Available From:</strong>
//           </label>
//           <input
//             type="date"
//             name="available_from"
//             value={addDealData.available_from}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Available To:</strong>
//           </label>
//           <input
//             type="date"
//             name="available_to"
//             value={addDealData.available_to}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Terms and Conditions:</strong>
//           </label>
//           <textarea
//             name="terms_and_conditions"
//             value={addDealData.terms_and_conditions}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Product/Service:</strong>
//           </label>
//           <input
//             type="text"
//             name="product_service"
//             value={addDealData.product_service}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Original Price:</strong>
//           </label>
//           <input
//             type="number"
//             name="original_price"
//             value={addDealData.original_price}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Discount:</strong>
//           </label>
//           <input
//             type="number"
//             name="discount"
//             value={addDealData.discount}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Net Price:</strong>
//           </label>
//           <input
//             type="number"
//             name="net_price"
//             value={addDealData.net_price}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <div>
//           <label>
//             <strong>Total Coupon Count:</strong>
//           </label>
//           <input
//             type="number"
//             name="deal_coupon_total_count"
//             value={addDealData.deal_coupon_total_count}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded w-full p-2"
//           />
//         </div>
//         <button
//           onClick={uploadImagesAndAddDeal}
//           className="bg-blue-500 text-white px-4 py-2 rounded"
//         >
//           Add Deal
//         </button>
//       </div>
//     </div>
//   </div>
// )}

//     </div>
//   );
// };

// export default Deals;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDeals,
  toggleDealStatus,
  updateDeal,
  addDeal,
} from '../../redux/actions/dealActions';
import { getVendorsAdmin, getCategoriesAdmin } from '../../api/api';
import { fetchLocations, addLocation } from '../../redux/actions/storeLocationActions';
import { uploadImageToSupabase } from '../../utils/uploadImages';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles


const Deals = () => {
  const dispatch = useDispatch();
  const { deals, error } = useSelector((state) => state.deals || {});

  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const { locations } = useSelector((state) => state.storeLocations || {});

  const [vendors, setVendors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [notification, setNotification] = useState(null);
  const [dealData, setDealData] = useState({});
  const [addDealData, setAddDealData] = useState({
    vendor_id: '',
    category_id: '',
    title: '',
    description: '',
    available_from: '',
    available_to: '',
    original_price: '',
    discount: '',
    net_price: '',
    deal_coupon_total_count: '',
    terms_and_conditions: '',
    product_service: '',
    images: [],
    store_location_ids: [],
  });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [dealIdInput, setDealIdInput] = useState("");

  useEffect(() => {
    dispatch(fetchDeals());
    fetchVendorsAndCategories();
  }, [dispatch]);

  const handleAddLocation = () => {
    const locationData = {
      vendor_id: addDealData.vendor_id,
      location_name: prompt('Enter new store location name:'),
    };
    if (locationData.location_name) {
      dispatch(addLocation(locationData));
    }
  };

  const fetchVendorsAndCategories = async () => {
    try {
      const fetchedVendors = await getVendorsAdmin();
      const fetchedCategories = await getCategoriesAdmin();
      console.log('Fetched Categories:', fetchedCategories); // Debugging output
      console.log('Fetched Vendors:', fetchedVendors); // Debugging output
      setVendors(fetchedVendors);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching vendors or categories:', error);
    }
  };
  

  const handleStatusToggle = (deal) => {
    setSelectedDeal(deal);
    setShowDeactivateModal(true);
  };

  const handleConfirmDeactivation = () => {
    const isActive = selectedDeal.deal_status === 'Active';
    dispatch(toggleDealStatus(selectedDeal.deal_id, isActive));
    setNotification(`Deal ${isActive ? 'deactivated' : 'activated'} successfully`);
    closeDeactivateModal();
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
    setDealIdInput("");
    setSelectedDeal(null);
  };

  const handleEditClick = (deal) => {
    setSelectedDeal(deal);
    setDealData({
      ...deal,
      available_from: deal.available_from.split('T')[0],
      available_to: deal.available_to.split('T')[0],
    });
    setShowEditModal(true);
  };

  const handleImageChangeEdit = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles((prevFiles) => [...prevFiles, ...files]); // Update image files state
    setDealData((prevData) => ({
      ...prevData,
      deal_images: [
        ...prevData.deal_images,
        ...files.map((file) => URL.createObjectURL(file)),
      ],
    })); // Update deal data with new image URLs
  };
  
  const handleRemoveImageEdit = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove image from state
    setDealData((prevData) => ({
      ...prevData,
      deal_images: prevData.deal_images.filter((_, i) => i !== index), // Remove image from deal data
    }));
  };
  
  const uploadImagesAndUpdateDeal = async () => {
    try {
      // Upload newly added images to Supabase and get their public URLs
      const uploadedImageUrls = await Promise.all(
        imageFiles.map((file) => uploadImageToSupabase(file))
      );

      const updatedFields = Object.keys(dealData).reduce((acc, key) => {
        if (dealData[key] !== selectedDeal[key]) {
          acc[key] = dealData[key];
        }
        return acc;
      }, {});
  
      // Combine existing and newly uploaded image URLs
      const updatedDealData = {
        ...updatedFields,
        deal_images: [...dealData.deal_images.filter((img) => !img.startsWith("blob:")), ...uploadedImageUrls],
      };
  
      // Dispatch the update action
      dispatch(updateDeal(selectedDeal.deal_id, updatedDealData));
  
      setNotification("Deal updated successfully!");
      closeEditModal();
    } catch (error) {
      console.error("Error updating deal images:", error);
      setNotification("Error updating deal images. Please try again.");
    }
  };
  

  // const handleUpdateDeal = () => {
    // const updatedFields = Object.keys(dealData).reduce((acc, key) => {
    //   if (dealData[key] !== selectedDeal[key]) {
    //     acc[key] = dealData[key];
    //   }
    //   return acc;
    // }, {});

  //   if (Object.keys(updatedFields).length === 0) {
  //     setNotification("No changes were made.");
  //     closeEditModal();
  //     return;
  //   }

  //   dispatch(updateDeal(selectedDeal.deal_id, updatedFields));
  //   closeEditModal();
  // };


  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < dealData.deal_images.length - 1 ? prevIndex + 1 : 0
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : dealData.deal_images.length - 1
    );
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedDeal(null);
    setDealData({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (showEditModal) {
      setDealData((prevData) => {
        let updatedData = { ...prevData, [name]: value };

        // Update Net Price when Original Price or Discount changes
        if (name === 'original_price' || name === 'discount') {
          const originalPrice = parseFloat(updatedData.original_price) || 0;
          const discount = parseFloat(updatedData.discount) || 0;

          // Ensure Net Price is always calculated correctly
          updatedData.net_price = (originalPrice - discount).toFixed(2);
        }

        return updatedData;
      });
    } else {
      setAddDealData((prevData) => {
        let updatedData = { ...prevData, [name]: value };

        // Update Net Price when Original Price or Discount changes
        if (name === 'original_price' || name === 'discount') {
          const originalPrice = parseFloat(updatedData.original_price) || 0;
          const discount = parseFloat(updatedData.discount) || 0;

          // Ensure Net Price is always calculated correctly
          updatedData.net_price = (originalPrice - discount).toFixed(2);
        }

        return updatedData;
      });
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles((prevFiles) => [...prevFiles, ...files]);
    setAddDealData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ...files.map((file) => URL.createObjectURL(file))],
    }));
  };

  const handleVendorChange = (vendorId) => {
    setAddDealData((prevData) => ({
      ...prevData,
      vendor_id: vendorId,
      store_location_ids: []
    }));
    
    if (vendorId) {
      dispatch(fetchLocations(vendorId));
      console.log(locations)
    }
  };

  const handleRemoveImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setAddDealData((prevData) => ({
      ...prevData,
      images: prevData.images.filter((_, i) => i !== index),
    }));
  };

  const uploadImagesAndAddDeal = async () => {
    try {
      // Upload images to Supabase and get their public URLs
      const uploadedImageUrls = await Promise.all(
        imageFiles.map((file) => uploadImageToSupabase(file))
      );
  
      // Proceed to add the deal with the uploaded image URLs
      handleAddDeal(uploadedImageUrls);
    } catch (error) {
      console.error('Error uploading images:', error);
      setNotification('Error uploading images. Please try again.');
    }
  };
  const handleDescriptionChange = (value) => {
    setAddDealData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };

  const handleTermsChange = (value) => {
    setAddDealData((prevData) => ({
      ...prevData,
      terms_and_conditions: value,
    }));
  };


  const handleTermsChangeEdit = (value) => {
    setDealData((prevData) => ({
      ...prevData,
      terms_and_conditions: value,
    }));
  };


  const handleDescriptionChangeEdit = (value) => {
    setDealData((prevData) => ({
      ...prevData,
      description: value,
    }));
  };

  const handleAddDeal = (uploadedImageUrls) => {
    if (!addDealData.vendor_id || !addDealData.store_location_ids) {
      setNotification('Please select a vendor and store location before adding a deal.');
      return;
    }
  
    const dealPayload = {
      ...addDealData,
      store_location_ids: Array.isArray(addDealData.store_location_ids)
        ? addDealData.store_location_ids
        : [addDealData.store_location_ids], // Ensure store_location_ids is an array
      images: uploadedImageUrls,
    };
  
    dispatch(addDeal(dealPayload));
    setShowAddModal(false);
    setAddDealData({
      vendor_id: '',
      category_id: '',
      title: '',
      description: '',
      available_from: '',
      available_to: '',
      original_price: '',
      discount: '',
      net_price: '',
      deal_coupon_total_count: '',
      terms_and_conditions: '',
      product_service: '',
      images: [],
      store_location_ids: [],
    });
    setImageFiles([]);
  };
  const closeAddModal = () => {
    setShowAddModal(false);
    setAddDealData({
      vendor_id: '',
      category_id: '',
      title: '',
      description: '',
      available_from: '',
      available_to: '',
      original_price: '',
      discount: '',
      net_price: '',
      deal_coupon_total_count: '',
      terms_and_conditions: '',
      product_service: '',
      images: [],
      store_location_ids: [],
    });
    setImageFiles([]);
  };

  const filteredDeals = deals?.filter(
    (deal) =>
      deal.deal_id.includes(searchTerm) ||
      deal.vendor_id.includes(searchTerm) ||
      deal.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Deals</h1>

      {notification && (
        <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
          <span>{notification}</span>
          <button className="ml-4" onClick={() => setNotification(null)}>
            ✖
          </button>
        </div>
      )}

      {/* Search bar and Add Deal button */}
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          placeholder="Search by Deal ID, Vendor ID, or Title"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border border-gray-300 rounded px-4 py-2 w-1/2"
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => setShowAddModal(true)}
        >
          Add Deal
        </button>
      </div>

      {/* Table */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 font-semibold text-left">Deal ID</th>
              <th className="py-3 px-6 font-semibold text-left">Vendor ID</th>
              <th className="py-3 px-6 font-semibold text-left">Title</th>
              <th className="py-3 px-6 font-semibold text-left">Original Price</th>
              <th className="py-3 px-6 font-semibold text-left">Net Price</th>
              <th className="py-3 px-6 font-semibold text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {filteredDeals &&
              filteredDeals.map((deal) => (
                <tr
                  key={deal.deal_id}
                  className={`border-b border-gray-200 ${
                    deal.deal_status === 'Active' ? 'bg-green-50' : 'bg-red-50'
                  } hover:bg-gray-100`}
                >
                  <td className="py-3 px-6 font-medium text-blue-600">{deal.deal_id.split('_')[1]}</td>
                  <td className="py-3 px-6 font-bold">{deal.vendor_id.split('_')[1]}</td>
                  <td className="py-3 px-6 font-bold">{deal.title}</td>
                  <td className="py-3 px-6 font-bold">${deal.original_price.toFixed(2)}</td>
                  <td className="py-3 px-6 font-bold">${deal.net_price.toFixed(2)}</td>
                  <td className="py-3 px-6">
                    <div className="flex items-center space-x-12">
                        <button
                        onClick={() => handleEditClick(deal)}
                        className="text-blue-500 font-medium"
                        >
                        View/Edit
                        </button>
                        <button
                        onClick={() => handleStatusToggle(deal)}
                        className={`font-medium px-4 py-2 rounded ${
                            deal.deal_status === 'Active'
                            ? 'bg-red-500 text-white'
                            : 'bg-blue-500 text-white'
                        } hover:opacity-80 transition-opacity`}
                        >
                        {deal.deal_status === 'Active' ? 'Deactivate' : 'Activate'}
                        </button>
                    </div>
                    </td>

                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Deactivate Modal */}
      {showDeactivateModal && selectedDeal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
            <button onClick={closeDeactivateModal} className="absolute top-2 right-2 text-gray-500">
              ✖
            </button>
            <h2 className="text-xl font-bold mb-4">Deactivate Deal</h2>
            <p>
              Would you like to deactivate the deal with Deal ID:{" "}
              <strong>{selectedDeal.deal_id.split("_")[1]}</strong>?
            </p>
            <input
              type="text"
              value={dealIdInput}
              onChange={(e) => setDealIdInput(e.target.value)}
              placeholder="Enter Deal ID to confirm"
              className="border border-gray-300 rounded w-full p-2 mt-4 mb-4"
            />
            <div className="flex justify-center space-x-4">
              <button
                onClick={closeDeactivateModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDeactivation}
                className="bg-red-500 text-white px-4 py-2 rounded"
                disabled={dealIdInput !== selectedDeal.deal_id.split("_")[1]}
              >
                {selectedDeal.deal_status === 'Active' ? 'Deactivate' : 'Activate'}
              </button>
            </div>
          </div>
        </div>
      )}

                  {/* Edit Modal */}
            {showEditModal && selectedDeal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
            <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">✖</button>
            
            {/* Left Section - Edit Deal Images */}
            <div className="flex flex-col items-center pr-6 border-r border-gray-200">
              <h3 className="text-lg font-bold mb-2">Edit Deal Images</h3>
              {dealData.deal_images && dealData.deal_images.length > 0 ? (
                <div className="relative">
                  <img
                    src={dealData.deal_images[currentImageIndex]}
                    alt="Deal Image"
                    className="h-64 w-full object-cover rounded mb-4"
                  />
                  <button
                    onClick={prevImage}
                    className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                  >
                    {"<"}
                  </button>
                  <button
                    onClick={nextImage}
                    className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
                  >
                    {">"}
                  </button>
                  <div className="flex justify-center mt-2 space-x-1">
                    {dealData.deal_images.map((_, index) => (
                      <span
                        key={index}
                        className={`h-2 w-2 rounded-full ${
                          index === currentImageIndex ? "bg-yellow-500" : "bg-gray-300"
                        }`}
                      />
                    ))}
                  </div>
                  <button
                    onClick={() => handleRemoveImageEdit(currentImageIndex)}
                    className="bg-red-500 text-white px-2 py-1 rounded mt-2"
                  >
                    Remove Image
                  </button>
                </div>
              ) : (
                <p>No images available</p>
              )}
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageChangeEdit}
                className="mt-4"
              />
            </div>

            {/* Right Section - Deal Details with Scrollable Content */}
            <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
              <div>
                <label><strong>Title:</strong></label>
                <input
                  type="text"
                  name="title"
                  value={dealData.title}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              {/* <div>
                <label><strong>Description:</strong></label>
                <input
                  type="text"
                  name="description"
                  value={dealData.description}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div> */}
              <div>
                <label>
                  <strong>Description:</strong>
                </label>
                <ReactQuill
                  value={dealData.description}
                  onChange={handleDescriptionChangeEdit}
                  className="bg-white"
                />
              </div>
              <div>
                <label>
                  <strong>Terms and Conditions:</strong>
                </label>
                <ReactQuill
                  value={dealData.terms_and_conditions}
                  onChange={handleTermsChangeEdit}
                  className="bg-white"
                />
              </div>
              <div>
                <label><strong>Available From:</strong></label>
                <input
                  type="date"
                  name="available_from"
                  value={dealData.available_from}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Available To:</strong></label>
                <input
                  type="date"
                  name="available_to"
                  value={dealData.available_to}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Product Service:</strong></label>
                <input
                  type="text"
                  name="product_service"
                  value={dealData.product_service}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Original Price:</strong></label>
                <input
                  type="number"
                  name="original_price"
                  value={dealData.original_price}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Net Price:</strong></label>
                <input
                  type="number"
                  name="Net_price"
                  value={dealData.net_price}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Discount:</strong></label>
                <input
                  type="number"
                  name="discount"
                  value={dealData.discount}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Total Coupon Count:</strong></label>
                <input
                  type="number"
                  name="deal_coupon_total_count"
                  value={dealData.deal_coupon_total_count}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Remaining Coupon Count:</strong></label>
                <input
                  type="number"
                  name="deal_coupon_remaining_count"
                  value={dealData.deal_coupon_remaining_count}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Category Name:</strong></label>
                <input
                  type="text"
                  name="category_name"
                  value={dealData.category_name}
                  disabled
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Created At:</strong></label>
                <input
                  type="text"
                  name="created_at"
                  value={dealData.created_at.split('T')[0]}
                  disabled
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Updated At:</strong></label>
                <input
                  type="text"
                  name="updated_at"
                  value={dealData.updated_at.split('T')[0]}
                  disabled
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <button
                onClick={uploadImagesAndUpdateDeal}
                className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                Save Changes
            </button>

            </div>
          </div>
        </div>
      )}
    {/* Add Deal Modal */}
{showAddModal && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
      <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">
        ✖
      </button>

      {/* Left Section - Add Images */}
      <div className="flex flex-col items-center pr-6 border-r border-gray-200">
        <h3 className="text-lg font-bold mb-2">Add Deal Images</h3>
        {addDealData.images && addDealData.images.length > 0 ? (
          <div className="relative">
            <img
              src={addDealData.images[currentImageIndex]}
              alt="Deal Preview"
              className="h-64 w-full object-cover rounded mb-4"
            />
            <button
              onClick={() =>
                setCurrentImageIndex((prevIndex) =>
                  prevIndex > 0 ? prevIndex - 1 : addDealData.images.length - 1
                )
              }
              className="absolute top-1/2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2"
            >
              {'<'}
            </button>
            <button
              onClick={() =>
                setCurrentImageIndex((prevIndex) =>
                  prevIndex < addDealData.images.length - 1 ? prevIndex + 1 : 0
                )
              }
              className="absolute top-1/2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
            >
              {'>'}
            </button>
            <div className="flex justify-center mt-2 space-x-1">
              {addDealData.images.map((_, index) => (
                <span
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === currentImageIndex ? 'bg-yellow-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
            <button
              onClick={() => handleRemoveImage(currentImageIndex)}
              className="bg-red-500 text-white px-2 py-1 rounded mt-2"
            >
              Remove Image
            </button>
          </div>
        ) : (
          <p>No images added</p>
        )}
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageChange}
          className="mt-4"
        />
      </div>

      {/* Right Section - Deal Details */}
      <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
        <div>
          <label>
            <strong>Vendor ID:</strong>
          </label>
          <select
            name="vendor_id"
            value={addDealData.vendor_id}
            onChange={(e) => handleVendorChange(e.target.value)}
            className="border border-gray-300 rounded w-full p-2"
          >
            <option value="">Select Vendor</option>
            {vendors
              .filter((vendor) => vendor.approved_status === true)
              .map((vendor) => (
                <option key={vendor.vendor_id} value={vendor.vendor_id}>
                  {vendor.business_name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>
            <strong>Category:</strong>
          </label>
          <select
            name="category_id"
            value={addDealData.category_id}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          >
            <option value="">Select Category</option>
            {categories.categories &&
              categories.categories.length > 0 &&
              categories.categories.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label>
            <strong>Store Location:</strong>
          </label>
          {locations && locations.locations && locations.locations.length > 0 ? (
            <select
              name="store_location_ids"
              value={addDealData.store_location_ids}
              onChange={handleInputChange}
              className="border border-gray-300 rounded w-full p-2"
            >
              <option value="">Select Store Location</option>
              {locations.locations.map((location) => (
                <option key={location.location_id} value={location.location_id}>
                  {location.address}, {location.zipcode}
                </option>
              ))}
            </select>
          ) : (
            <div className="flex items-center space-x-4">
              <p>No active store locations available.</p>
              <button
                onClick={handleAddLocation}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Add Store Location
              </button>
            </div>
          )}
        </div>
        <div>
          <label>
            <strong>Title:</strong>
          </label>
          <input
            type="text"
            name="title"
            value={addDealData.title}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        {/* <div>
          <label>
            <strong>Description:</strong>
          </label>
          <textarea
            name="description"
            value={addDealData.description}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div> */}
         <div>
                <label>
                  <strong>Description:</strong>
                </label>
                <ReactQuill
                  value={addDealData.description}
                  onChange={handleDescriptionChange}
                  className="bg-white"
                />
              </div>
        <div>
          <label>
            <strong>Available From:</strong>
          </label>
          <input
            type="date"
            name="available_from"
            value={addDealData.available_from}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <div>
          <label>
            <strong>Available To:</strong>
          </label>
          <input
            type="date"
            name="available_to"
            value={addDealData.available_to}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        {/* <div>
          <label>
            <strong>Terms and Conditions:</strong>
          </label>
          <textarea
            name="terms_and_conditions"
            value={addDealData.terms_and_conditions}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div> */}
        <div>
                <label>
                  <strong>Terms and Conditions:</strong>
                </label>
                <ReactQuill
                  value={addDealData.terms_and_conditions}
                  onChange={handleTermsChange}
                  className="bg-white"
                />
              </div>
        <div>
          <label>
            <strong>Product/Service:</strong>
          </label>
          <input
            type="text"
            name="product_service"
            value={addDealData.product_service}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <div>
          <label>
            <strong>Original Price:</strong>
          </label>
          <input
            type="number"
            name="original_price"
            value={addDealData.original_price}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <div>
          <label>
            <strong>Discount:</strong>
          </label>
          <input
            type="number"
            name="discount"
            value={addDealData.discount}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <div>
          <label>
            <strong>Net Price:</strong>
          </label>
          <input
            type="number"
            name="net_price"
            value={addDealData.net_price}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <div>
          <label>
            <strong>Total Coupon Count:</strong>
          </label>
          <input
            type="number"
            name="deal_coupon_total_count"
            value={addDealData.deal_coupon_total_count}
            onChange={handleInputChange}
            className="border border-gray-300 rounded w-full p-2"
          />
        </div>
        <button
          onClick={uploadImagesAndAddDeal}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Deal
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Deals;
