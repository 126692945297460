import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendors, updateVendor, addVendor } from '../../redux/actions/vendorActions';
import { uploadImageToSupabase } from '../../utils/uploadImages';
import { getDealsAdmin, getCategoriesAdmin } from '../../api/api';
import { FaFilter, FaEdit } from 'react-icons/fa';

const Vendors = () => {
  const dispatch = useDispatch();
  const { vendors, error } = useSelector((state) => state.vendors || {});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [notification, setNotification] = useState(null);
  const [vendorData, setVendorData] = useState({});
  const [newVendorData, setNewVendorData] = useState({
    name: '',
    contact_name: '',
    business_name: '',
    vendor_email: '',
    phone_number: '',
    category_id: '',
    address: '',
    zipcode: '',
    city: '',
    state: '',
    business_logo: '',
    facebook_link: '',
    insta_link: '',
    website_link: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("both");
  const [categories, setCategories] = useState([]);
  const [vendorIdInput, setVendorIdInput] = useState("");
  const [logoFile, setLogoFile] = useState(null); // For editing business logo

  useEffect(() => {
    dispatch(fetchVendors());
    fetchCategories();
  }, [dispatch]);

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await getCategoriesAdmin();
      setCategories(fetchedCategories.categories || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleStatusToggle = (vendor) => {
    const vendorHasActiveDeals = vendor.Deals.some((deal) => deal.deal_status === 'Active');
    if (vendorHasActiveDeals) {
      setNotification("Please deactivate all the active deals before deactivating the vendor.");
      return;
    }
    setSelectedVendor(vendor);
    setShowDeactivateModal(true);
  };

  const handleConfirmDeactivation = () => {
    const deactivate = selectedVendor.approved_status;
    dispatch(updateVendor(selectedVendor.vendor_id, {}, deactivate, "changeStatus"));
    setNotification(`Vendor ${deactivate ? "deactivated" : "activated"} successfully`);
    closeDeactivateModal();
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
    setVendorIdInput("");
    setSelectedVendor(null);
  };

  const handleSaveChanges = async () => {
    try {
      let updatedLogo = vendorData.business_logo;

      if (logoFile) {
        updatedLogo = await uploadImageToSupabase(logoFile);
      }

      const { business_logo, approved_status, vendor_subscription_status, created_at, updated_at, Deals, ...dataToUpdate } = vendorData;
      dataToUpdate.business_logo = updatedLogo;

      dispatch(updateVendor(selectedVendor.vendor_id, dataToUpdate, null, 'UpdateVendor'));
      setNotification("Vendor details updated successfully");
      closeEditModal();
    } catch (error) {
      console.error("Error updating vendor:", error);
      setNotification("Error updating vendor. Please try again.");
    }
  };

  const handleEditClick = (vendor) => {
    setSelectedVendor(vendor);
    setVendorData({ ...vendor });
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedVendor(null);
    setVendorData({});
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setNewVendorData({
      name: '',
      contact_name: '',
      business_name: '',
      vendor_email: '',
      phone_number: '',
      category_id: '',
      address: '',
      zipcode: '',
      city: '',
      state: '',
      business_logo: '',
      facebook_link: '',
      insta_link: '',
      website_link: '',
    });
    setImageFile(null);
  };

  const handleInputChange = (e, isNewVendor = false) => {
    const { name, value } = e.target;
    if (isNewVendor) {
      setNewVendorData({ ...newVendorData, [name]: value });
    } else {
      setVendorData({ ...vendorData, [name]: value });
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) setLogoFile(file);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) setImageFile(file);
  };

  const handleSaveNewVendor = async () => {
    try {
      if (!newVendorData.business_name || !newVendorData.contact_name || !newVendorData.address || !newVendorData.zipcode || !newVendorData.city || !newVendorData.state || !newVendorData.vendor_email || !newVendorData.phone_number || !newVendorData.category_id) {
        setNotification("Please fill in all the mandatory fields.");
        return;
      }

      if (imageFile) {
        const publicUrl = await uploadImageToSupabase(imageFile);
        newVendorData.business_logo = publicUrl;
        newVendorData.user_name = 'vendor';
        newVendorData.password_hash = 'password';
        newVendorData.name = newVendorData.business_name;
      }
      dispatch(addVendor(newVendorData));
      setNotification("New vendor added successfully");
      closeAddModal();
    } catch (error) {
      console.error("Error adding vendor:", error);
      setNotification("Error adding vendor. Please try again.");
    }
  };

  const handleErrorClose = () => {
    setNotification(null);
  };

  const handleFilterChange = () => {
    setFilterStatus((prev) => (prev === 'both' ? 'active' : prev === 'active' ? 'inactive' : 'both'));
  };

  const filteredVendors = vendors?.filter((vendor) => {
    const matchesSearchTerm =
      (vendor.vendor_id && vendor.vendor_id.includes(searchTerm)) ||
      (vendor.business_name && vendor.business_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (vendor.name && vendor.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesStatus =
      filterStatus === "both" ||
      (filterStatus === "active" && vendor.approved_status) ||
      (filterStatus === "inactive" && !vendor.approved_status);

    return matchesSearchTerm && matchesStatus;
  });

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Vendors</h1>

      {/* Notification Banner */}
      {notification && (
        <div className="fixed top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg flex items-center">
          <span>{notification}</span>
          <button className="ml-4" onClick={handleErrorClose}>✖</button>
        </div>
      )}

      {/* Search bar, Filters, and Add Vendor button */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="Search by Vendor ID, Business Name, or Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border border-gray-300 rounded px-4 py-2 w-full"
          />
        </div>
        <button onClick={handleAddClick} className="bg-blue-500 text-white px-4 py-2 rounded">
          Add Vendor
        </button>
      </div>

      {/* Table */}
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 font-semibold text-left">Vendor ID</th>
              <th className="py-3 px-6 font-semibold text-left">Business Name</th>
              <th className="py-3 px-6 font-semibold text-left">Name</th>
              <th className="py-3 px-6 font-semibold text-left">
                <div className="flex items-center space-x-2">
                  <span>Status</span>
                  <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="bg-gray-200 text-gray-600 text-sm rounded px-2 py-1 border border-gray-400"
                  >
                    <option value="both">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
              </th>
              <th className="py-3 px-6 font-semibold text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {filteredVendors?.map((vendor) => (
              <tr
                key={vendor.vendor_id}
                className={`border-b border-gray-200 ${
                  vendor.approved_status ? "bg-green-50" : "bg-red-50"
                } hover:bg-gray-100`}
              >
                <td className="py-3 px-6 font-medium text-blue-600">{vendor.vendor_id}</td>
                <td className="py-3 px-6 font-bold">{vendor.business_name}</td>
                <td className="py-3 px-6 font-bold">{vendor.name}</td>
                <td className="py-3 px-6 font-bold">
                  {vendor.approved_status ? "Active" : "Inactive"}
                </td>
                <td className="py-3 px-6">
                  <div className="flex items-center space-x-16">
                    <button
                      onClick={() => handleEditClick(vendor)}
                      className="text-blue-500 font-medium"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleStatusToggle(vendor)}
                      className={`font-medium px-4 py-2 rounded ${
                        vendor.approved_status
                          ? "bg-red-500 text-white"
                          : "bg-blue-500 text-white"
                      } hover:opacity-80 transition-opacity`}
                    >
                      {vendor.approved_status ? "Deactivate" : "Activate"}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Deactivate Vendor Modal */}
      {showDeactivateModal && selectedVendor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full relative">
            <button onClick={closeDeactivateModal} className="absolute top-2 right-2 text-gray-500">✖</button>
            <h2 className="text-xl font-bold mb-4">Deactivate Vendor</h2>
            <p>Please enter the vendor ID <strong>{selectedVendor.vendor_id.split("_")[1]}</strong> {selectedVendor.approved_status ? "to deactivate" : "to activate"}</p>
            <input
              type="text"
              value={vendorIdInput}
              onChange={(e) => setVendorIdInput(e.target.value)}
              className="border border-gray-300 rounded w-full p-2 mt-2 mb-4"
            />
            <div className="flex justify-center space-x-2 ">
              <button onClick={closeDeactivateModal} className="bg-gray-300 text-gray-700 px-4 py-2 rounded">
                Cancel
              </button>
              <button
                onClick={handleConfirmDeactivation}
                className="bg-red-500 text-white px-4 py-2 rounded"
                disabled={vendorIdInput !== selectedVendor.vendor_id.split("_")[1]}
              >
                {selectedVendor.approved_status ? "Deactivate" : "Activate"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Vendor Modal */}
      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full relative">
            <button onClick={closeAddModal} className="absolute top-2 right-2 text-gray-500">✖</button>
            <h2 className="text-xl font-bold mb-4">Add Vendor</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label><strong>Contact Name:</strong></label>
                <input
                  type="text"
                  name="contact_name"
                  value={newVendorData.contact_name}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Business Name:</strong></label>
                <input
                  type="text"
                  name="business_name"
                  value={newVendorData.business_name}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Email:</strong></label>
                <input
                  type="email"
                  name="vendor_email"
                  value={newVendorData.vendor_email}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Phone Number:</strong></label>
                <input
                  type="text"
                  name="phone_number"
                  value={newVendorData.phone_number}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Category:</strong></label>
                <select
                  name="category_id"
                  value={newVendorData.category_id}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                >
                  <option value="">Select Category</option>
                  {categories.length > 0 &&
                    categories.map((category) => (
                      <option key={category.category_id} value={category.category_id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <label><strong>Street:</strong></label>
                <input
                  type="text"
                  name="address"
                  value={newVendorData.address}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Zip Code:</strong></label>
                <input
                  type="text"
                  name="zipcode"
                  value={newVendorData.zipcode}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>City:</strong></label>
                <input
                  type="text"
                  name="city"
                  value={newVendorData.city}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>State:</strong></label>
                <input
                  type="text"
                  name="state"
                  value={newVendorData.state}
                  onChange={(e) => handleInputChange(e, true)}
                  className="border border-gray-300 rounded w-full p-2"
                  required
                />
              </div>
              <div>
                <label><strong>Business Logo:</strong></label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button onClick={handleSaveNewVendor} className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                Save Vendor
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Vendor Modal */}
      {showEditModal && selectedVendor && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg max-w-4xl w-full flex relative">
            <button onClick={closeEditModal} className="absolute top-2 right-2 text-gray-500">✖</button>

            {/* Left Section - Logo and Vendor ID */}
            <div className="flex flex-col items-center pr-6 border-r border-gray-200">
              <img
                src={
                  logoFile
                    ? URL.createObjectURL(logoFile) // Show preview of new logo if uploaded
                    : vendorData.business_logo
                }
                alt="Vendor Logo"
                className="h-32 w-32 rounded-full mb-4"
              />
              <label className="text-blue-500 cursor-pointer mt-2">
                <FaEdit className="inline mr-1" />
                Edit Logo
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="hidden"
                />
              </label>
              <div className="text-blue-600 font-medium">{vendorData.business_name}</div>
            </div>

            {/* Right Section - Vendor Details */}
            <div className="flex-1 pl-6 overflow-y-auto max-h-96 space-y-4">
              <div>
                <label><strong>Name:</strong></label>
                <input
                  type="text"
                  name="name"
                  value={vendorData.name}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-1/2 p-2"
                />
              </div>
              <div>
                <label><strong>License Number:</strong></label>
                <input
                  type="text"
                  name="license_number"
                  value={vendorData.license_number}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Facebook link:</strong></label>
                <input
                  type="text"
                  name="facebook_link"
                  value={vendorData.facebook_link}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Insta link:</strong></label>
                <input
                  type="text"
                  name="insta_link"
                  value={vendorData.insta_link}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Website link:</strong></label>
                <input
                  type="text"
                  name="website_link"
                  value={vendorData.website_link}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Street:</strong></label>
                <input
                  type="text"
                  name="address"
                  value={vendorData.address}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Business Name:</strong></label>
                <input
                  type="text"
                  name="business_name"
                  value={vendorData.business_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Email:</strong></label>
                <input
                  type="email"
                  name="vendor_email"
                  value={vendorData.vendor_email}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                />
              </div>
              <div>
                <label><strong>Phone Number:</strong></label>
                <input
                  type="text"
                  name="phone_number"
                  value={vendorData.phone_number}
                  onChange={handleInputChange}
                  className="border border-gray-300 rounded w-full p-2"
                  maxLength="15"
                />
              </div>
              <div className="flex justify-end mt-4">
                <button onClick={handleSaveChanges} className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Vendors;
