// // dealReducer.js

// import {
//     FETCH_DEALS_SUCCESS,
//     FETCH_DEALS_ERROR,
//     UPDATE_DEAL_SUCCESS,
//     UPDATE_DEAL_ERROR,
//   } from "../types";
  
//   const initialState = {
//     deals: [],
//     error: null,
//   };
  
//   const dealReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_DEALS_SUCCESS:
//         return { ...state, deals: action.payload, error: null };
//       case FETCH_DEALS_ERROR:
//         return { ...state, deals: [], error: action.payload };
//       case UPDATE_DEAL_SUCCESS:
//         return {
//           ...state,
//           deals: state.deals.map((deal) =>
//             deal.deal_id === action.payload.deal_id ? action.payload : deal
//           ),
//           error: null,
//         };
//       case UPDATE_DEAL_ERROR:
//         return { ...state, error: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default dealReducer;
  

import {
    FETCH_DEALS_SUCCESS,
    FETCH_DEALS_ERROR,
    ADD_DEAL_SUCCESS,
    ADD_DEAL_ERROR,
    UPDATE_DEAL_SUCCESS,
    UPDATE_DEAL_ERROR,
  } from '../types';
  
  const initialState = {
    deals: [],
    error: null,
  };
  
  const dealReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DEALS_SUCCESS:
        return { ...state, deals: action.payload, error: null };
      case FETCH_DEALS_ERROR:
        return { ...state, deals: [], error: action.payload };
      case ADD_DEAL_SUCCESS:
        return { ...state, deals: [...state.deals, action.payload], error: null };
      case ADD_DEAL_ERROR:
        return { ...state, error: action.payload };
      case UPDATE_DEAL_SUCCESS:
        return {
          ...state,
          deals: state.deals.map((deal) =>
            deal.deal_id === action.payload.deal_id ? action.payload : deal
          ),
          error: null,
        };
      case UPDATE_DEAL_ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };
  
  export default dealReducer;
  