// storeLocationReducer.js

import {
    FETCH_LOCATIONS_SUCCESS,
    FETCH_LOCATIONS_ERROR,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_ERROR,
  } from '../types';
  
  const initialState = {
    locations: [],
    error: null,
  };
  
  const storeLocationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LOCATIONS_SUCCESS:
        return { ...state, locations: action.payload, error: null };
      case FETCH_LOCATIONS_ERROR:
        return { ...state, locations: [], error: action.payload };
      case ADD_LOCATION_SUCCESS:
        return { ...state, locations: [...state.locations, action.payload], error: null };
      case ADD_LOCATION_ERROR:
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };
  
  export default storeLocationReducer;
  