// // dealActions.js

// import { getDealsAdmin, updateDealAdmin, deactivateDeal, activateDeal } from '../../api/api';
// import {
//   FETCH_DEALS_SUCCESS,
//   FETCH_DEALS_ERROR,
//   UPDATE_DEAL_SUCCESS,
//   UPDATE_DEAL_ERROR,
// } from '../types';

// export const fetchDeals = () => async (dispatch) => {
//   try {
//     const deals = await getDealsAdmin();
//     dispatch({ type: FETCH_DEALS_SUCCESS, payload: deals });
//   } catch (error) {
//     dispatch({ type: FETCH_DEALS_ERROR, payload: error.message });
//   }
// };

// export const updateDeal = (dealId, updates) => async (dispatch) => {
//     try {
//       const updatedDeal = await updateDealAdmin(dealId, updates);
//       dispatch({ type: UPDATE_DEAL_SUCCESS, payload: updatedDeal });
//       dispatch(fetchDeals()); // Refresh deals list
//     } catch (error) {
//       dispatch({ type: UPDATE_DEAL_ERROR, payload: error.message });
//     }
//   };

// export const toggleDealStatus = (dealId, isActive) => async (dispatch) => {
//     try {
//       const response = isActive ? await deactivateDeal(dealId) : await activateDeal(dealId);
//       dispatch({ type: UPDATE_DEAL_SUCCESS, payload: response });
//       dispatch(fetchDeals()); // Refresh deals list after update
//     } catch (error) {
//       dispatch({ type: UPDATE_DEAL_ERROR, payload: error.message });
//     }
//   };

import {
    getDealsAdmin,
    addDealAdmin,
    updateDealAdmin,
    deactivateDeal,
    activateDeal,
  } from '../../api/api';
  import {
    FETCH_DEALS_SUCCESS,
    FETCH_DEALS_ERROR,
    ADD_DEAL_SUCCESS,
    ADD_DEAL_ERROR,
    UPDATE_DEAL_SUCCESS,
    UPDATE_DEAL_ERROR,
  } from '../types';
  
  // Fetch all deals
  export const fetchDeals = () => async (dispatch) => {
    try {
      const deals = await getDealsAdmin();
      dispatch({ type: FETCH_DEALS_SUCCESS, payload: deals });
    } catch (error) {
      dispatch({ type: FETCH_DEALS_ERROR, payload: error.message });
    }
  };
  
  // Add a new deal
  export const addDeal = (dealData) => async (dispatch) => {
    try {
      const newDeal = await addDealAdmin(dealData);
      dispatch({ type: ADD_DEAL_SUCCESS, payload: newDeal });
      dispatch(fetchDeals()); // Refresh deals list after adding
    } catch (error) {
      dispatch({ type: ADD_DEAL_ERROR, payload: error.message });
    }
  };
  
  // Update a deal
  export const updateDeal = (dealId, updates) => async (dispatch) => {
    try {
      const updatedDeal = await updateDealAdmin(dealId, updates);
      dispatch({ type: UPDATE_DEAL_SUCCESS, payload: updatedDeal });
      dispatch(fetchDeals()); // Refresh deals list after update
    } catch (error) {
      dispatch({ type: UPDATE_DEAL_ERROR, payload: error.message });
    }
  };
  
  // Toggle deal status
  export const toggleDealStatus = (dealId, isActive) => async (dispatch) => {
    try {
      const response = isActive ? await deactivateDeal(dealId) : await activateDeal(dealId);
      dispatch({ type: UPDATE_DEAL_SUCCESS, payload: response });
      dispatch(fetchDeals()); // Refresh deals list after update
    } catch (error) {
      dispatch({ type: UPDATE_DEAL_ERROR, payload: error.message });
    }
  };
  