// import { FETCH_VENDORS_SUCCESS, FETCH_VENDORS_ERROR } from '../types';

// const initialState = {
//   vendors: [],
//   error: null,
// };

// const vendorReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_VENDORS_SUCCESS:
//       return { ...state, vendors: action.payload, error: null };
//     case FETCH_VENDORS_ERROR:
//       return { ...state, vendors: [], error: action.payload };
//     default:
//       return state;
//   }
// };

// export default vendorReducer;


// import {
//     FETCH_VENDORS_SUCCESS,
//     FETCH_VENDORS_ERROR,
//     UPDATE_VENDOR_SUCCESS,
//     UPDATE_VENDOR_ERROR,
//   } from '../types';
  
//   const initialState = {
//     vendors: [],
//     error: null,
//   };
  
//   const vendorReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case FETCH_VENDORS_SUCCESS:
//         return { ...state, vendors: action.payload, error: null };
//       case FETCH_VENDORS_ERROR:
//         return { ...state, vendors: [], error: action.payload };
//       case UPDATE_VENDOR_SUCCESS:
//         // Update the specific vendor in the state
//         return {
//           ...state,
//           vendors: state.vendors.map((vendor) =>
//             vendor.vendor_id === action.payload.vendor_id ? action.payload : vendor
//           ),
//           error: null,
//         };
//       case UPDATE_VENDOR_ERROR:
//         return { ...state, error: action.payload };
//       default:
//         return state;
//     }
//   };
  
//   export default vendorReducer;
  

import {
    FETCH_VENDORS_SUCCESS,
    FETCH_VENDORS_ERROR,
    UPDATE_VENDOR_SUCCESS,
    UPDATE_VENDOR_ERROR,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_ERROR,
    ADD_VENDOR_SUCCESS,
    ADD_VENDOR_ERROR,
  } from '../types';
  
  const initialState = {
    vendors: [],
    categories: [],
    error: null,
  };
  
  const vendorReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_VENDORS_SUCCESS:
        return { ...state, vendors: action.payload, error: null };
      case FETCH_VENDORS_ERROR:
        return { ...state, vendors: [], error: action.payload };
      case UPDATE_VENDOR_SUCCESS:
        return {
          ...state,
          vendors: state.vendors.map((vendor) =>
            vendor.vendor_id === action.payload.vendor_id ? action.payload : vendor
          ),
          error: null,
        };
      case UPDATE_VENDOR_ERROR:
        return { ...state, error: action.payload };
      case ADD_VENDOR_SUCCESS:
        return { ...state, vendors: [...state.vendors, action.payload], error: null };
      case ADD_VENDOR_ERROR:
        return { ...state, error: action.payload };
      case FETCH_CATEGORIES_SUCCESS:
        return { ...state, categories: action.payload, error: null };
      case FETCH_CATEGORIES_ERROR:
        return { ...state, categories: [], error: action.payload };
      default:
        return state;
    }
  };
  
  export default vendorReducer;
  