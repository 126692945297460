import React from 'react';
import { FaUserFriends, FaTags, FaThList, FaSignOutAlt } from 'react-icons/fa';
import { AiOutlineDashboard } from 'react-icons/ai';
import logo from '../assets/logo-wah.png'; // Ensure this path is correct

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-gray-800 text-white flex flex-col justify-between shadow-lg">
      <div>
        {/* Logo */}
        <div className="p-6 flex items-center justify-center">
          <img src={logo} alt="WAH Admin Panel" className="h-16" />
        </div>

        {/* Navigation Links */}
        <nav className="mt-6">
          <ul className="space-y-2 text-sm">
            {/* Dashboard */}
            <li>
              <a href="#" className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg">
                <AiOutlineDashboard className="mr-3 text-lg" />
                <span>Dashboard</span>
              </a>
            </li>
            {/* Vendors */}
            <li>
              <a href="/vendors" className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg">
                <FaUserFriends className="mr-3 text-lg" />
                <span>Vendors</span>
              </a>
            </li>
            {/* Customers */}
            <li>
              <a href="#" className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg">
                <FaUserFriends className="mr-3 text-lg" />
                <span>Customers</span>
              </a>
            </li>
            {/* Deals */}
            <li>
              <a href="/Deals" className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg">
                <FaTags className="mr-3 text-lg" />
                <span>Deals</span>
              </a>
            </li>
            {/* Categories */}
            <li>
              <a href="/Categories" className="flex items-center px-6 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg">
                <FaThList className="mr-3 text-lg" />
                <span>Categories</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {/* Sign Out */}
      <div className="p-6">
        <a href="/" className="flex items-center px-6 py-2 text-gray-300 hover:text-red-500 hover:bg-gray-700 rounded-lg">
          <FaSignOutAlt className="mr-3 text-lg" />
          <span>Sign Out</span>
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
