import { combineReducers } from 'redux';
import vendorReducer from './vendorReducer';
import dealReducer from './dealReducer';
import categoriesReducer from './categoriesReducer';
import storeLocationReducer from './storeLocationReducer';

export default combineReducers({
  vendors: vendorReducer,
  deals: dealReducer,
  categories: categoriesReducer,
  storeLocations: storeLocationReducer
});
