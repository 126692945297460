import { getCategoriesAdmin, addCategoryAdmin, updateCategoryAdmin, deleteCategoryAdmin } from '../../api/api';
import {
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
} from '../types';

export const fetchCategories = () => async (dispatch) => {
  try {
    const response = await getCategoriesAdmin(); // Make the API call
    dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: response }); // Pass the entire response to the reducer
  } catch (error) {
    dispatch({ type: FETCH_CATEGORIES_ERROR, payload: error.message }); // Dispatch error message
  }
};

export const addCategory = (categoryData) => async (dispatch) => {
    try {
      await addCategoryAdmin(categoryData);
      dispatch(fetchCategories());
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };
  
  export const updateCategory = (categoryData) => async (dispatch) => {
    try {
      await updateCategoryAdmin(categoryData);
      dispatch(fetchCategories());
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  export const deleteCategory = (categoryId) => async (dispatch) => {
    try {
      await deleteCategoryAdmin(categoryId);
      dispatch(fetchCategories()); // Refresh categories after deletion
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };
  
