import {
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_ERROR,
  } from '../types';
  
  const initialState = {
    categories: [],
    error: null,
  };
  
  const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CATEGORIES_SUCCESS:
        return { ...state, categories: action.payload.categories, error: null }; // Extract `categories` from the payload
      case FETCH_CATEGORIES_ERROR:
        return { ...state, categories: [], error: action.payload }; // Corrected `error` assignment
      default:
        return state;
    }
  };
  
  export default categoriesReducer;
  