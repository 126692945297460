
// import axios from 'axios';

// const API_URL = "https://infnamnlgddsnzcttpfq.supabase.co/functions/v1";
// const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImluZm5hbW5sZ2Rkc256Y3R0cGZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgwOTU3NTMsImV4cCI6MjA0MzY3MTc1M30.euUMOwXDZQv3Lu2UvPEZXj5pj200JOi4rEuaiGHwTg4';

// export const getVendorsAdmin = async () => {
//   try {
//     const response = await axios.get(`${API_URL}/getVendorsAdmin`, {
//       headers: {
//         'Authorization': AUTH_TOKEN,
//       },
//       maxBodyLength: Infinity,
//     });
//     return response.data.data; // Returns the vendor data
//   } catch (error) {
//     console.error("Error fetching vendors:", error);
//     throw error;
//   }
// };

// export const updateVendorAdmin = async (vendorId, updates = {}, deactivate = false, action) => {
//   try {
//     const response = await axios.post(
//       `${API_URL}/updateVendorsAdmin`,
//       {
//         vendorId,
//         updates,
//         deactivate,
//         action
//       },
//       {
//         headers: {
//           'Authorization': AUTH_TOKEN,
//           'Content-Type': 'application/json',
//         },
//       }
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error updating vendor:", error);
//     throw error;
//   }
// };


// api.js

import axios from 'axios';

const API_URL = "https://infnamnlgddsnzcttpfq.supabase.co/functions/v1";
const AUTH_TOKEN = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImluZm5hbW5sZ2Rkc256Y3R0cGZxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjgwOTU3NTMsImV4cCI6MjA0MzY3MTc1M30.euUMOwXDZQv3Lu2UvPEZXj5pj200JOi4rEuaiGHwTg4';

// Fetch all vendors
export const getVendorsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getVendorsAdmin`, {
      headers: { 'Authorization': AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching vendors:", error);
    throw error;
  }
};

//add new vendor
export const addVendorAdmin = async (vendorData) => {
    try {
      const response = await axios.post(`${API_URL}/addVendor`, vendorData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding vendor:", error);
      throw error;
    }
  };
  

// Update vendor details or status
export const updateVendorAdmin = async (vendorId, updates = {}, deactivate = false, action) => {
  try {
    const response = await axios.post(
      `${API_URL}/updateVendorsAdmin`,
      { vendorId, updates, deactivate, action },
      { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating vendor:", error);
    throw error;
  }
};

// Fetch all deals
export const getDealsAdmin = async () => {
  try {
    const response = await axios.get(`${API_URL}/getDealsAdmin`, {
      headers: { 'Authorization': AUTH_TOKEN },
      maxBodyLength: Infinity,
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching deals:", error);
    throw error;
  }
};

// Add a new deal
export const addDealAdmin = async (dealData) => {
    try {
      const response = await axios.post(`${API_URL}/addDeal`, dealData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error("Error adding deal:", error);
      throw error;
    }
  };

export const updateDealAdmin = async (dealId, updates) => {
    try {
      const response = await axios.post(
        `${API_URL}/updateDealsAdmin`,
        { dealId, updates },
        {
          headers: {
            Authorization: AUTH_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error("Error updating deal:", error);
      throw error;
    }
  };
  

// Deactivate a deal
export const deactivateDeal = async (dealId) => {
    try {
      const response = await axios.post(
        `${API_URL}/deactivateDeal`,
        { deal_id: dealId },
        { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (error) {
      console.error("Error deactivating deal:", error);
      throw error;
    }
  };
  
  // Activate a deal
  export const activateDeal = async (dealId) => {
    try {
      const response = await axios.post(
        `${API_URL}/activateDeal`,
        { deal_id: dealId },
        { headers: { 'Authorization': AUTH_TOKEN, 'Content-Type': 'application/json' } }
      );
      return response.data;
    } catch (error) {
      console.error("Error activating deal:", error);
      throw error;
    }
  };



  export const getCategoriesAdmin = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/getCategories`,
        {
          headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
        }
      );
      return response.data; // Return the entire response to preserve the `categories` key
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  };
  
  export const updateCategoryAdmin = async (categoryData) => {
    try {
      const response = await axios.post(
        `${API_URL}/updateCategoryAdmin`,
        categoryData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating category:", error);
      throw error;
    }
  };
  
  export const addCategoryAdmin = async (categoryData) => {
    try {
      const response = await axios.post(
        `${API_URL}/addCategoryAdmin`,
        categoryData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding category:", error);
      throw error;
    }
  };

  export const deleteCategoryAdmin = async (categoryId) => {
    try {
      const response = await axios.post(
        `${API_URL}/deleteCategoryAdmin`,
        { categoryId },
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  };
  
  // Fetch vendor locations
export const getVendorLocations = async (vendorId) => {
    try {
      const response = await axios.get(`${API_URL}/fetchVendorLocations?vendor_id=${vendorId}`, {
        headers: { Authorization: AUTH_TOKEN },
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching vendor locations:', error);
      throw error;
    }
  };
  
  // Add a new vendor location
  export const addVendorLocation = async (locationData) => {
    try {
      const response = await axios.post(`${API_URL}/addVendorLocation`, locationData, {
        headers: { Authorization: AUTH_TOKEN, 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error('Error adding vendor location:', error);
      throw error;
    }
  };
  

  export const validateAdmin = async (loginData) => {
    try {
      const response = await axios.post(
        `${API_URL}/validateAdmin`,
        loginData,
        {
          headers: { Authorization: AUTH_TOKEN, "Content-Type": "application/json" },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error validating admin:", error);
      throw error;
    }
  };