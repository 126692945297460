// // import logo from './logo.svg';
// // import './App.css';

// // function App() {
// //   return (
// //     <div className="App">
// //       <header className="App-header">
// //         <img src={logo} className="App-logo" alt="logo" />
// //         <p>
// //           Edit <code>src/App.js</code> and save to reload.
// //         </p>
// //         <a
// //           className="App-link"
// //           href="https://reactjs.org"
// //           target="_blank"
// //           rel="noopener noreferrer"
// //         >
// //           Learn React
// //         </a>
// //       </header>
// //     </div>
// //   );
// // }

// // export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Layout from './components/Layout';
// import Vendors from './pages/vendors/Vendors';
// import Customers from './pages/customers/Customers';
// import Deals from './pages/vendors/Deals';
// import Categories from './pages/vendors/Categories';
// import Login from './pages/Login';

// function App() {
//   return (
//     <Router>
//       <Layout>
//         <Routes>

//           <Route path="/" element={<Login />} />
//           <Route path="/vendors" element={<Vendors />} />
//           <Route path="/customers" element={<Customers />} />
//           <Route path="/Deals" element={<Deals />} />
//           <Route path="/Categories" element={<Categories />} />
//         </Routes>
//       </Layout>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Vendors from './pages/vendors/Vendors';
import Customers from './pages/customers/Customers';
import Deals from './pages/vendors/Deals';
import Categories from './pages/vendors/Categories';
import Login from './pages/Login';

function App() {
  return (
    <Router>
      <ConditionalLayout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/vendors" element={<Vendors />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/Deals" element={<Deals />} />
          <Route path="/Categories" element={<Categories />} />
        </Routes>
      </ConditionalLayout>
    </Router>
  );
}

// A wrapper component to conditionally render the Layout
const ConditionalLayout = ({ children }) => {
  const location = useLocation();

  // Define routes that do not require the Layout
  const noLayoutRoutes = ['/'];

  // Check if the current route is in the noLayoutRoutes array
  const shouldRenderLayout = !noLayoutRoutes.includes(location.pathname);

  return shouldRenderLayout ? <Layout>{children}</Layout> : <>{children}</>;
};

export default App;
