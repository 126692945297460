// types.js

export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_ERROR = "FETCH_VENDORS_ERROR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_ERROR = "UPDATE_VENDOR_ERROR";
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR';


// Deals
export const FETCH_DEALS_SUCCESS = "FETCH_DEALS_SUCCESS";
export const FETCH_DEALS_ERROR = "FETCH_DEALS_ERROR";
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS";
export const ADD_DEAL_ERROR = "ADD_DEAL_ERROR";
export const UPDATE_DEAL_SUCCESS = "UPDATE_DEAL_SUCCESS";
export const UPDATE_DEAL_ERROR = "UPDATE_DEAL_ERROR";

export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_ERROR = "FETCH_CATEGORIES_ERROR";

// Store Locations
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_ERROR = 'FETCH_LOCATIONS_ERROR';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_ERROR = 'ADD_LOCATION_ERROR';
