// storeLocationActions.js

import { getVendorLocations, addVendorLocation } from '../../api/api';
import {
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_ERROR,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_ERROR,
} from '../types';

// Fetch all locations for a specific vendor
export const fetchLocations = (vendorId) => async (dispatch) => {
  try {
    const locations = await getVendorLocations(vendorId);
    dispatch({ type: FETCH_LOCATIONS_SUCCESS, payload: locations });
  } catch (error) {
    dispatch({ type: FETCH_LOCATIONS_ERROR, payload: error.message });
  }
};

// Add a new location
export const addLocation = (locationData) => async (dispatch) => {
  try {
    const newLocation = await addVendorLocation(locationData);
    dispatch({ type: ADD_LOCATION_SUCCESS, payload: newLocation });
    dispatch(fetchLocations(locationData.vendor_id)); // Refresh the locations list after adding
  } catch (error) {
    dispatch({ type: ADD_LOCATION_ERROR, payload: error.message });
  }
};
